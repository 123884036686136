import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/Layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`We are building data-centric tools that help measure worker experience, give workers control and stewardship over the data they produce, and explore the future of data governance strategies. Our first project, a collaboration with Coworker, created an automated texting service to pool data on gig worker pay. We’re currently working on app-based tools to allow gig workers to measure their own expenses and calculate their effective hourly wage to help them plan for the future. By using new data governance models, Gig Box will also give workers the option to aggregate their data with other workers to provide researchers, organizers, and other stakeholders important information about the state of the gig economy.`}</p>
    <p>{`We believe that:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Workers, and gig workers in particular, suffer from information asymmetry: their lives are governed by algorithms and information that they cannot see or control`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Aggregate information about working conditions like number of hours worked, average pay and expenses, or where drivers find the most requests is often collected and analyzed by employers, but not made available to workers.`}</li>
          <li parentName="ul">{`While app-based companies often frame workers as equal ‘partners’, this information asymmetry enforces a kind of control over workers' schedules, routines, and pay.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Giving workers the ability to pool data also builds power`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`In app-based industries where this information asymmetry is the main way that companies control workers, democratizing access to information helps workers regain control over their working lives.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Aggregate app-based worker data is crucial for planning the future of work`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Because collecting information from app-based workers is so difficult, researchers barely understand their pay structures, business expenses, or hours worked. This makes planning policy for the future of millions of workers almost impossible.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Workers deserve to control the data they generate while at work`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Data that workers create while working, like their location histories, time spent searching for jobs, their pay history, and other information should belong to those workers first – not a company, nonprofit, or other entity they don’t control.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`About Us`}</h2>
    <p>{`Gigbox is a project run by `}<a parentName="p" {...{
        "href": "https://media.mit.edu/people/dcalacci"
      }}>{`Dan Calacci`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://www.media.mit.edu/groups/human-dynamics/overview/"
      }}>{`Human Dynamics Group`}</a>{`, advised by `}<a parentName="p" {...{
        "href": "https://www.media.mit.edu/people/sandy/overview/"
      }}>{`Sandy Pentland`}</a>{` at the MIT Media Lab. Our goal is to give organizers, workers, and community members broader access to privacy-preserving tools they can use to create better knowledge about their working conditions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      