// src/components/layout.js
// import React from "react"
//
// import { Link } from "gatsby"
// import { YouTube, Twitter, TomatoBox } from "./ui"

// const shortcodes = { Link, YouTube, Twitter, TomatoBox }

// export default ({ children }) => (
//
// )

import React, { useState } from "react";
import { graphql } from "gatsby";
import Header from "../../components/Header";
import { components } from "../../../root-wrapper";
import { MDXProvider } from "@mdx-js/react";

export default (props) => {
  console.log("props:", props);
  return (
    <>
      <Header large={false} />
      <div className="flex w-full justify-center bg-white text-black">
        <article className="flex flex-col w-full h-full sm:max-w-xl pt-20">
          <h1 className="font-bold text-4xl">{props.pageTitle}</h1>
          <div className="max-w-xl self-center">{props.children}</div>
        </article>
      </div>
    </>
  );
};
